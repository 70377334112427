import React from 'react';
import * as styles from './EpisodePreviewGrid.module.css';

import EpisodePreview from '../EpisodePreview';

const EpisodePreviewGrid = (props) => {
  const { data  } = props;
  return (
    <div className={styles.root}>
      {data &&
        data.map((episode, index) => {
          return (
            <EpisodePreview
              key={index}
              title={episode.title}
              guest={episode.guest}
              link={episode.link}
              category={episode.category}
            />
          );
        })}
    </div>
  );
};

export default EpisodePreviewGrid;
