import React from 'react';
import * as styles from './EpisodePreview.module.css';

const EpisodePreview = (props) => {
  const { guest, title, link, category } =
    props;

  return (
    <div className={styles.root}>
      <iframe
        className={styles.video}
        src={link}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope;"
        allowfullscreen>
      </iframe>
      <p className={styles.category}>{category}</p>
      <h4 className={styles.title}>{title}</h4>
      <h4 className={styles.guest}>{guest}</h4>
    </div>
  );
};

export default EpisodePreview;
