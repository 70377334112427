import * as React from 'react';

import Container from '../components/Container';
import Hero from '../components/Hero';
import EpisodePreviewGrid from '../components/EpisodePreviewGrid';
import Layout from '../components/Layout/Layout';
import Title from '../components/Title';

import { generateEpisodeData } from '../helpers/general';

import * as styles from './index.module.css';
import { navigate } from 'gatsby';

const IndexPage = () => {
  const featuredEpisodeData = generateEpisodeData(3);

  const goToWatch = () => {
    navigate('https://www.youtube.com/@morethanmainstream');
  };

  return (
    <Layout>
      {/* Hero Container */}
      <Hero
        maxWidth={'1750px'}
        image={'/banner1.png'}
        title={'Long format conversations about \n technology, society, and everything in between'}
        ctaText={'Watch now'}
        ctaAction={goToWatch}
      />

      {/* Episode Grid */}
      <div className={styles.blogsContainer}>
        <Container size={'large'}>
          <Title name={'Featured Episodes'} />
          <EpisodePreviewGrid data={featuredEpisodeData} />
        </Container>
      </div>
    </Layout>
  );
};

export default IndexPage;
